<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Line')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
                <label>{{ $t('name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.situation"
                  class="mt-2"
                  :state="errors.situation ?false:null"
                  :placeholder="$t('situation')"
                />
                <small
                  v-if="errors.situation"
                  class="text-danger"
                >{{ errors.situation[0] }}</small>
                <label>{{ $t('situation') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.takeon"
                  class="mt-2"
                  :state="errors.takeon ?false:null"
                  :placeholder="$t('takeon')"
                />
                <small
                  v-if="errors.takeon"
                  class="text-danger"
                >{{ errors.takeon[0] }}</small>
                <label>{{ $t('takeon') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.takeoff"
                  class="mt-2"
                  :state="errors.takeoff ?false:null"
                  :placeholder="$t('takeoff')"
                />
                <small
                  v-if="errors.takeoff"
                  class="text-danger"
                >{{ errors.takeoff[0] }}</small>
                <label>{{ $t('takeoff') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.symbol"
                  class="mt-2"
                  :state="errors.symbol ?false:null"
                  :placeholder="$t('symbol')"
                />
                <small
                  v-if="errors.symbol"
                  class="text-danger"
                >{{ errors.symbol[0] }}</small>
                <label>{{ $t('symbol') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('Unavailable/Available') }}</label>
                <b-form-checkbox
                  v-model="data.status"
                  :value="true"
                  :state="errors.status ?false:null"
                >
                  {{ data.status ? $t('Unavailable') : $t('Available') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.status"
                class="text-danger"
              >{{ errors.status[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="data.note"
                  class="mt-2"
                  rows="3"
                  max-rows="9"
                  :state="errors.note ?false:null"
                  :placeholder="$t('Note')"
                />
                <small
                  v-if="errors.note"
                  class="text-danger"
                >{{ errors.note[0] }}</small>
                <label>{{ $t('Note') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md=""
              class="d-flex justify-content-center"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <upload
                    v-model="data.img_id"
                    @on-file-error="UpdateFileError"
                  />
                </b-col>
                <b-col cols="12">
                  <b-alert
                    v-if="fileErrors.length>0"
                    variant="danger"
                    show
                  >
                    <h4 class="alert-heading">
                      {{ $t('Upload Image Error') }}
                    </h4>
                    <div class="alert-body">
                      <span>
                        <ul
                          v-for="(val,index) in fileErrors"
                          :key="index"
                        >
                          <li>{{ val }}</li>
                        </ul>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col><b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="create"
              >
                {{ $t('Create') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {},
  created() {},
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      // this.data.part = 'text'
      this.errors = []
      this.$store.dispatch('line/Create', this.data).then(() => {
        this.data = {}
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
